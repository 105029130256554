import React from 'react'
import waris from '../imgs/waris.jpg'
import usama from '../imgs/usama.jpeg'
import ashar from '../imgs/ashar.jpeg'

export default function Team() {
    return (
        <div className='mt-10'>
            <h1 className='text-xl md:text-3xl font-bold'>
                Team
            </h1>
            <hr className=' opacity-30' />
            <div className='grid grid-cols-1 md:grid-cols-3 mt-10 space-y-5'>
                <div className=' flex flex-col items-center space-y-5'>
                    <img src={waris} className='rounded-full w-32 h-32 md:w-48 md:h-48 object-cover' alt="" />
                    <p className=' font-bold'>M. Waris Mahmood</p>
                    <p>20F-0355</p>
                </div>
                <div className=' flex flex-col items-center space-y-5'>
                    <img src={usama} className='rounded-full w-32 h-32 md:w-48 md:h-48 object-cover' alt="" />
                    <p className=' font-bold'>Usama Rehman</p>
                    <p>20F-0127</p>
                </div>
                <div className=' flex flex-col items-center space-y-5'>
                    <img src={ashar} className='rounded-full w-32 h-32 md:w-48 md:h-48 object-cover' alt="" />
                    <p className=' font-bold'>Ashar Khan</p>
                    <p>20F-1002</p>
                </div>

            </div>

        </div>
    )
}
