
import './App.css';
import Links from './components/Links';
import Header from './components/Header';
import PhaseOne from './components/PhaseOne';
import Team from './components/Team';


function App() {
  return (
    <>
      <div className=' bg-background'>
        <Header />
        <main className=' mt-28 md:mt-0 p-16 md:p-44'>
          <PhaseOne />
          <Team />
          <Links />
        </main>

      </div>

    </>
  );
}

export default App;
