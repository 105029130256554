import React from 'react'
import icon from '../imgs/devop.png'
export default function Header() {
    return (
        <>
            <div className='h-screen p-24 md:p-44  bg-gradient-to-t from-background to-secondary'>
                <div className='flex flex-col md:flex-row'>
                    <div className=' '>
                        <h1 className='text-2xl md:text-5xl font-bold'>
                            DevOps Project
                        </h1>

                        <p className='w-64 md:w-[30rem] mt-12 ml-1 text-xl'>
                            DevOps is a methodology in the software development and IT industry. Used as a set of practices and tools, DevOps integrates and automates the work of software development and IT operations as a means for improving and shortening the systems development life cycle
                        </p>
                    </div>
                    <img src={icon} className='w-52 md:w-[15rem] md:ml-80 mt-14  ' alt="" srcset="" />
                    
                </div>


            </div>
        </>





    )
}
