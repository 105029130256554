import React from 'react'
import slack from '../imgs/slack.png'
import notion from '../imgs/notion.png'
import github from '../imgs/GitHub.png'

export default function Links() {
  return (
    <div className='mt-10'>
    <h1 className='text-xl md:text-3xl font-bold'>
        Links
    </h1>
    <hr className=' opacity-30' />
    <div className='grid grid-cols-1 md:grid-cols-3 mt-10 space-y-5'>
        <div className=' flex flex-col items-center space-y-5'>
            <img src={slack} className=' w-12 h-12 md:w-24 md:h-24 object-cover' alt="" />
            <p className=' font-bold'>Slack Channel</p>
             <a target='_blank' rel='noreferrer'  href="https://f23sqeandado.slack.com/archives/C05SGUL5LBV"> Visit</a>
        </div>
        <div className=' flex flex-col items-center space-y-5'>
            <img src={notion} className=' w-12 h-12 md:w-24 md:h-24 object-cover' alt="" />
            <p className=' font-bold'>Notion Workplace</p>
            <a target='_blank' rel='noreferrer'  href="https://www.notion.so/a605ab5a8ec64c7d8dbfcf755778a795?v=fbfde2ba36794fa58fd3a9169abeaf09"> Visit</a>

        </div>
        <div className=' flex flex-col items-center space-y-5'>
            <img src={github} className=' w-12 h-12 md:w-24 md:h-24 object-cover' alt="" />
            <p className=' font-bold'>Github Repo</p>
            <a target='_blank' rel='noreferrer'  href="https://github.com/m-waris/zzcave-pigeons"> Visit</a>

        </div>

    </div>

</div>
  )
}
