import React from 'react'
import tick from '../imgs/tick.png'
export default function PhaseOne() {
    return (
        <>
            <div className=''>
                <h1 className='text-xl md:text-3xl font-bold'>
                    Project Phase 1
                </h1>
                <hr  className=' opacity-30'/>

                <div className='mt-5'>
                    <h2 className=' text-xl'>
                        Task 1: Create Team and workspaces
                    </h2>
                    <ul className='mt-5 space-y-5'>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Create a team of 3 students</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Create a private channel on slack</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Create a private channel on slack</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Create a private channel on slack</p>
                        </li>
                    </ul>

                </div>
                
                <div className='mt-10'>
                    <h2 className=' text-xl'>
                        Task 2: Conduct a meeting and select project goals
                    </h2>
                    <ul className='mt-5 space-y-5'>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Schedule a weekly meeting on google calender</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Exploring DevOps for web application</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Exploring DevOps for mobile applicationk</p>
                        </li>
                    </ul>

                </div>

                <div className='mt-10'>
                    <h2 className=' text-xl'>
                    Task 3: Explore CI/CD tools and setup an initial CI/CD tool for your project
                    </h2>
                    <ul className='mt-5 space-y-5'>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Explore different tools</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Document your findings </p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Clone a given repository</p>
                        </li>
                    </ul>



                </div>

                <div className='mt-10'>
                    <h2 className=' text-xl'>
                    Task 4: Deploy a basic web application on a cloud and document the details                    </h2>
                    <ul className='mt-5 space-y-5'>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Create accounts on cloud service</p>
                        </li>
                        <li className='flex'>
                            <img src={tick} alt="" className='w-7 mr-2' />
                            <p className='mt-1'>Manually deploye the application </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
